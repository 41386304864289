import React from "react"
import { graphql } from "gatsby"
import LandingPage from "../../Layout/LandingPage"
import sectionStyle from "LandingPageMaterial/Views/Sections/sectionStyle"
import { PanelBanner } from "./Components/PanelBanner"
import { PreguntasFrecuentes } from "./Components/PreguntasFrecuentes"
import { PanelDescriptivo } from "./Components/PanelDescriptivo"
import { CleanText } from "./Functions/CleanText"
import { SectionBotonLateral } from "./Components/SectionBotonLateral"
import { SeccionPoliza } from "./Components/SeccionPoliza"
import { styled } from "@mui/material"

const NewDiv = styled("div")(({ theme, ownerState }) => {
  const { style } = ownerState
  return { ...sectionStyle[style] }
})

const NewH2 = styled("h2")(({ theme, className }) => ({
  ...className,
}))

const gridItemAutoClasses = {
  ...sectionStyle.mlAuto,
  ...sectionStyle.mrAuto,
}

export default ({ data }) => {
  const seccionBanners = data.allStrapiSeccionBannersNvl2.edges
  const seccionDescriptivaPagina =
    data.allStrapiSeccionDescriptivaPaginasNvl2.edges
  const seccionBotonLateral = data.allStrapiSeccionBotonLateralesNvl2.edges
  const seccionInformativaTexto =
    data.allStrapiSeccionInformativaTextosNvl2.edges
  const seccionBotonPieParrafo = data.allStrapiSeccionBotonPieParrafosNvl2.edges
  const seccionPoliza = data.allStrapiSeccionPolizasNvl2.edges
  const seccionPreguntaFrecuente =
    data.allStrapiSeccionPreguntaFrecuentesNvl2.edges

  const nombreBanner = seccionBanners[0].node.nombre_banner
    ?.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
  const areaProducto = seccionBanners[0].node.area_producto
    ?.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
  const nombreBannerSinEspacios = nombreBanner.replace(/\s+/g, "")
  const urlToMeta =
    "PolizadeSeguros/" + areaProducto.trim() + "/" + nombreBannerSinEspacios
    const empaquetado = data.allStrapiEmpaquetadosNvl2.edges[0]?.node

  const newArraySeccionLateral = seccionBotonLateral.map((element) => {
    // Encontramos el texto relacionado con el mismo código de botón lateral
    const relationatedText = seccionInformativaTexto.find((text) => {
      const isRelationated =
        text.node?.codigo_boton_lateral?.codigo_boton_lateral ===
        element.node?.codigo_boton_lateral
      return isRelationated // Retorna true si hay coincidencia
    })

    // Estructuramos los datos solo si existe relationatedText
    const formattedRelationatedText = relationatedText
      ? {
          codigo_boton_lateral:
            relationatedText.node?.codigo_boton_lateral.codigo_boton_lateral,
          codigo_producto:
            relationatedText.node?.codigo_producto.codigo_producto,
          codigo_seccion_informativa:
            relationatedText.node?.codigo_seccion_informativa,
          imagen_desktop: relationatedText.node?.imagen_desktop.localFile.url,
          imagen_mobile: relationatedText.node?.imagen_mobile.localFile.url,
          imagen_tablet: relationatedText.node?.imagen_tablet.localFile.url,
          nombre_producto: relationatedText.node?.nombre_producto,
          parrafo: CleanText(relationatedText.node?.parrafo.data.parrafo),
        }
      : null

    const relationatedBotonPiePagina = seccionBotonPieParrafo
      .filter(
        (boton) =>
          boton.node?.codigo_boton_lateral?.codigo_boton_lateral ===
          element.node?.codigo_boton_lateral
      )
      .map((boton) => ({
        codigo_boton_lateral:
          boton.node.codigo_boton_lateral.codigo_boton_lateral,
        codigo_producto: boton.node.codigo_producto.codigo_producto,
        nombre_producto: boton.node.nombre_producto,
        codigo_seccion_informativa:
          boton.node.codigo_seccion_informativa.codigo_seccion_informativa, // Puedes añadir más campos aquí
        nombre_boton: boton.node.nombre_boton,
        orden_boton: boton.node.orden_boton,
        url: boton.node.url,
      }))
      .sort((a, b) => a.orden_boton - b.orden_boton)
    // Creamos el nuevo objeto que incluye el texto relacionado formateado
    const newElement = {
      ...element.node,
      codigo_producto: element.node.codigo_producto.codigo_producto,
      relationatedText: formattedRelationatedText,
      pieDePagina: relationatedBotonPiePagina,
    }

    return newElement
  })

  const Separator = styled("div")({
    marginTop: "90px",
    backgroundColor: "white",
    "@media (max-width: 470px)": {
      marginTop: "70px",
    },
  })

  const ContainerPages = styled("section")({
    margin: "-50px auto 0 auto",
    paddingTop: "2rem",
    position: "relative",
    zIndex: 3,
    color: "#7c7b7b",
    backgroundColor: "white",
    borderRadius: "6px",
    width: "85%",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    "@media (max-width: 1500px)": {
      width: "95%",
      // paddingRight:'100px',
      // paddingLeft:'100px',
    },
    "@media (max-width: 768px)": {
      width: "95%",
      // paddingRight:'20px',
      // paddingLeft:'20px',
      margin: "-20px auto 0 auto",
    },
    "@media (max-width: 600px)": {
      width: "92%",
      margin: "-30px auto 0 auto",
    },
  })

  //   const navtabs = productos.map(({ node }) => {
  //     const id = node.codigo_producto
  //     const coberturas = coberturasT.filter(
  //       ({ node }) => node.producto?.codigo_producto === id
  //     )
  //     return {
  //       titulo: node.nombre_producto,
  //       id: node.id,
  //       component: tabProducto(node, coberturas),
  //     }
  //   })

  {
    /* <LandingPage pageTitle={" Productos "}>
<Separator>
  <PanelBanner panelBanners={panelBanners} panelPaginaGenerales={panelPaginaGenerales}/>
  <ContainerPages>
    {sortedArray.length > 0 && <PanelInformativo panelInfomativoData={sortedArray[0]} />}
    <PanelBeneficios panelBeneficios={panelBeneficios} backg={true} />
      { 
        sortedArray.length > 1 
        &&
          sortedArray.slice(1).map((item, i) => {
            return (
              <PanelInformativo key={i} panelInfomativoData={item} backg={i%2 === 0 ? false : true } />
            )
          })
      }
    <PreguntasFrecuentes listOfFaqs={panelPreguntaFrecuentes}/>
  </ContainerPages>
  <Contactanos panelContactanos={panelContactanos}/>
</Separator>
</LandingPage> */
  }

  const titleSeccionPoliza = seccionBanners[0]?.node.nombre_banner
  console.log("titleSeccionPoliza", titleSeccionPoliza)

  return (
    <LandingPage pageTitle={" Productos "} urlToMeta={urlToMeta} empaquetado={empaquetado}>
      <Separator>
        <PanelBanner panelBanners={seccionBanners} />
        <ContainerPages>
          <PanelDescriptivo
            seccionDescriptivaPagina={seccionDescriptivaPagina}
          />
          <SectionBotonLateral
            seccionLateral={newArraySeccionLateral}
            seccionDescriptivaPagina={seccionDescriptivaPagina}
          />
          <SeccionPoliza
            seccionPoliza={seccionPoliza}
            title={titleSeccionPoliza}
          />
          <PreguntasFrecuentes listOfFaqs={seccionPreguntaFrecuente} />
        </ContainerPages>
      </Separator>
    </LandingPage>
  )
}

export const query = graphql`
  query ($id: String!) {
    allStrapiSeccionBannersNvl2: allStrapiSeccionBannerNvl2(
      filter: { codigo_producto: { eq: $id } }
    ) {
      edges {
        node {
          nombre_banner
          area_producto
          texto_principal
          texto_secundario
          texto_terciario
          codigo_producto
          imagen_desktop {
            localFile {
              url
            }
          }
          imagen_mobile {
            localFile {
              url
            }
          }
          imagen_tablet {
            localFile {
              url
            }
          }
        }
      }
    }
    allStrapiSeccionDescriptivaPaginasNvl2: allStrapiSeccionDescriptivaPaginaNvl2(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          nombre_producto
          parrafo {
            data {
              parrafo
            }
          }
          codigo_producto {
            codigo_producto
          }
          titulo_seccion_descriptiva
        }
      }
    }
    allStrapiSeccionBotonLateralesNvl2: allStrapiSeccionBotonLateralNvl2(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          nombre_producto
          nombre_boton
          codigo_boton_lateral
          orden_boton
          codigo_producto {
            codigo_producto
          }
        }
      }
    }

    allStrapiSeccionInformativaTextosNvl2: allStrapiSeccionInformativaTextoNvl2(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          nombre_producto
          codigo_boton_lateral {
            codigo_boton_lateral
          }
          codigo_seccion_informativa
          codigo_producto {
            codigo_producto
          }
          imagen_desktop {
            localFile {
              url
            }
          }
          imagen_mobile {
            localFile {
              url
            }
          }
          imagen_tablet {
            localFile {
              url
            }
          }
          parrafo {
            data {
              parrafo
            }
          }
        }
      }
    }
    allStrapiSeccionBotonPieParrafosNvl2: allStrapiSeccionBotonPieParrafoNvl2(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          nombre_producto
          nombre_boton
          url
          orden_boton
          codigo_seccion_informativa {
            codigo_seccion_informativa
          }
          codigo_producto {
            codigo_producto
          }
          codigo_boton_lateral {
            codigo_boton_lateral
          }
        }
      }
    }

    allStrapiSeccionPolizasNvl2: allStrapiSeccionPolizaNvl2(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          nombre_poliza
          codigo_poliza
          url
          codigo_producto {
            codigo_producto
          }
          parrafo {
            data {
              parrafo
            }
          }
          imagen_desktop {
            localFile {
              url
            }
          }
          imagen_mobile {
            localFile {
              url
            }
          }
          imagen_tablet {
            localFile {
              url
            }
          }
        }
      }
    }

    allStrapiSeccionPreguntaFrecuentesNvl2: allStrapiSeccionPreguntaFrecuenteNvl2(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          nombre_producto
          orden
          pregunta
          respuesta {
            data {
              respuesta
            }
          }
          codigo_producto {
            codigo_producto
          }
        }
      }
    }
    allStrapiEmpaquetadosNvl2: allStrapiEmpaquetadoNvl2(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          Meta_tag
          Title_tag
        }
      }
    }
  }
`
